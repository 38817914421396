import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

const AutoSuggestObj = ({
  apiCall, apiName, resetAction, setValue, cancelAction, fvalue, keyy,
}) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const apiResponse = useSelector((state) => state[apiName]);
  // const [searchVal, setSearchVal] = useState('')

  useEffect(() => {
    if (apiResponse.flag && !apiResponse.loading) {
      if (apiResponse.data.data.length > 0) {
        setResult([...apiResponse.data.data]);
      } else {
        setResult([])
      }
    }
    if (apiResponse.loading) {
      setResult([])
    }
  }, [JSON.stringify(apiResponse)]);

  const handleSearch = (value) => {
    if (value.trim()) {
      dispatch(cancelAction())
      apiCall(value);
      dispatch(resetAction())
    } else {
      setResult([])
      dispatch(resetAction())
    }
  };

  // const onFocus = () => {
  //   apiCall(searchVal || '');
  // }

  const handleOnChange = (key, value) => {
    const temp = value.map((itm, idx) => {
      if (Object.keys(itm).length <= 0) {
        return fvalue[idx]
      }

      return { key: itm.key, value: itm.value }
    })
    if (value.length > 0) {
      // setSearchVal(value)
      setValue(temp)
    } else {
      // setSearchVal([])
      setValue([])
    }
  }
  return (
    <Select
      mode="tags"
      loading={apiResponse.loading}
      value={fvalue}
      // onFocus={onFocus}
      onChange={handleOnChange}
      className="select-input-tag"
      onSearch={handleSearch}
      placeholder="Type to search"
      onBlur={() => {
        dispatch(cancelAction())
        setResult([])
        dispatch(resetAction())
      }}
      notFoundContent={apiResponse.loading ? ' ' : 'No data found'}
    >
      {result.map((res) => (
        keyy === 'sponsor' ? (
          <Option key={res.value} value={res.key}>
            {res.key}
          </Option>
        ) : (
          <Option key={res.key} value={res.key}>
            {res.key}
          </Option>
        )
      ))}
    </Select>
  );
};

export default AutoSuggestObj;
