import { parse } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { getTabsDeepdiveListAction, tabsDeepdiveResetAction } from '../DeepDiveScreen/logic';
import './about.scss'

export default function CTDeepDiveAbout() {
  const dispatch = useDispatch();
  const [tabsTrialDataObj, setTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }
  const tabsDeepdiveResponse = useSelector((store) => store.tabsDeepdiveResponse)

  useEffect(() => {
    dispatch(getTabsDeepdiveListAction({
      clinical_id: nctId,
      tabname: 'About',
    }))
  }, [])

  useEffect(() => {
    if (tabsDeepdiveResponse.flag) {
      setTabsTrialDataObj(tabsDeepdiveResponse.data.data)
      dispatch(tabsDeepdiveResetAction())
    }
  }, [JSON.stringify(tabsDeepdiveResponse)])

  return (
    <div>
      <Loader loading={tabsDeepdiveResponse.loading} error={tabsDeepdiveResponse.error}>
        <div className="trial-generalsection trial-sec-container">
          <div className="trial-id-parent top-parent-row">
            <div className="top-parent-row-in">
              <div className="trail-id-parent-title trial-sec-main-title">
                TRIAL ID
              </div>
              <div className="trail-id-parent-desc trial-sec-desc">
                {tabsTrialDataObj.trial_id ? <>{tabsTrialDataObj.trial_id}</> : <>-</>}
              </div>
            </div>
            <div className="top-parent-row-in">
              <div className="trail-id-parent-title trial-sec-main-title">
                LandSCAPE ID
              </div>
              <div className="trail-id-parent-desc trial-sec-desc">
                {tabsTrialDataObj.landscape_id ? <>{tabsTrialDataObj.landscape_id}</> : <>-</>}
              </div>
            </div>
            <div className="top-parent-row-in">
              <div className="trail-id-parent-title trial-sec-main-title">
                ENROLLED SUBJECTS
              </div>
              <div className="trail-id-parent-desc trial-sec-desc">
                {tabsTrialDataObj.enrollment_subjects ? <>{tabsTrialDataObj.enrollment_subjects}</> : <>-</>}
              </div>
            </div>
            <div className="top-parent-row-in">
              <div className="trail-id-parent-title trial-sec-main-title">
                PAREXEL STUDY ID
              </div>
              <div className="trail-id-parent-desc trial-sec-desc">
                {tabsTrialDataObj.pxl_study_no ? <>{tabsTrialDataObj.pxl_study_no}</> : <>-</>}
              </div>
            </div>
            <div className="top-parent-row-in">
              <div className="trail-id-parent-title trial-sec-main-title">
                Therapeutic Objective Code
              </div>
              <div className="trail-id-parent-desc trial-sec-desc">
                {tabsTrialDataObj.therapeutic_objective_code ? <>{tabsTrialDataObj.therapeutic_objective_code}</> : <>-</>}
              </div>
            </div>
            <div className="top-parent-row-in">
              <div className="trail-id-parent-title trial-sec-main-title">
                Total Payment
              </div>
              <div className="trail-id-parent-desc trial-sec-desc">
                {tabsTrialDataObj.total_payment ? <>{tabsTrialDataObj.payment_currency} {tabsTrialDataObj.total_payment.toFixed(2)}</> : <>-</>}
              </div>
            </div>
          </div>

          <div className="trial-id-parent">
            <div className="trail-id-parent-title trial-sec-main-title">
              TRIAL SNAPSHOT
            </div>
            <div className="grid-container">
              {tabsTrialDataObj?.table && tabsTrialDataObj?.table?.map((itm) => {
                return (
                  <div className="trail-id-parent grid-item">
                    <div className="trial-item-title">{itm.key}</div>
                    <div className="trail-id-parent-desc trial-sec-desc">
                      {(Array.isArray(itm?.value) && itm?.value?.length) > 0 ? itm.value.map((j) => {
                        return <div>{typeof j === 'object' ? j.name : j}</div>
                      }) : itm.value === '' ? '-' : itm.value}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="trial-title-parent">
            <div className="trail-id-parent-title trial-sec-title">
              ABSTRACT
            </div>
            <div className="trail-title-parent-desc trial-sec-desc">
              {tabsTrialDataObj?.Abstract ? tabsTrialDataObj?.Abstract : '-'}
            </div>
          </div>

          <div className="trial-title-parent">
            <div className="trail-id-parent-title trial-sec-title">
              DESCRIPTION
            </div>
            <div className="trail-title-parent-desc trial-sec-desc">
              {tabsTrialDataObj?.Description ? tabsTrialDataObj?.Description : '-'}
            </div>
          </div>
        </div>
      </Loader>
    </div>
  )
}
