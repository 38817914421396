import { Radio } from 'antd';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { getHistoryChangeDataAction } from './logic';
import './historyChange.scss'

export default function CTDeepDiveHistoryOfChanges() {
  const dispatch = useDispatch();
  const [mentionTrialDataObj, setMentionTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }
  const getHistoryChangeDataResponse = useSelector((store) => store.getHistoryChangeDataResponse)
  const [compareValue, setComapareValue] = useState('');
  const [radiostate, setRadioState] = useState({ male: '', female: '' });

  useEffect(() => {
    dispatch(getHistoryChangeDataAction({
      clinical_id: nctId,
      from: 1,
      size: 50,
    }))
  }, [])

  useEffect(() => {
    if (getHistoryChangeDataResponse.flag) {
      setMentionTabsTrialDataObj(getHistoryChangeDataResponse.data.data)
    }
  }, [JSON.stringify(getHistoryChangeDataResponse)])

  const onChange = (e) => {
    setComapareValue(e.target.value);
  };

  const handleRadioChange = (event, id, type) => {
    setRadioState((oldstate) => {
      const temp = { ...oldstate };
      temp[type] = id;
      return temp;
    });
  };

  return (
    <div className="main-head">
      <Loader
        loading={getHistoryChangeDataResponse.loading}
        error={getHistoryChangeDataResponse.error}
      >
        {(mentionTrialDataObj && mentionTrialDataObj.length > 0) && (
        <div className="history-of-changes-tab">
          <div className="head-wrapper">
            <div className="text-wrapper"> Comparison Format: </div>
            <div className="compare-wrapper">
              <Radio.Group onChange={onChange} value={compareValue}>
                <Radio value="Merged">Merged</Radio>
                <Radio value="Side-by-Side">Side-by-side</Radio>
              </Radio.Group>
            </div>
            <div className="compare-btn-content">
              <a
                target="_blank"
                href={`https://clinicaltrials.gov/ct2/history/NCT03934216?A=${radiostate.A}&B=${radiostate.B}&C=${compareValue}#StudyPageTop`}
                rel="noopener noreferrer"
                className="compare-btn"
              >
                Compare
              </a>
            </div>
          </div>
          <div className="history-of-changes-table">
            <div className="table-head-wrapper">
              <div className="table-wrap"> Version </div>
              <div className="table-wrap"> A </div>
              <div className="table-wrap"> B </div>
              <div className="table-wrap"> Submitted Date </div>
              <div className="table-wrap"> Changes </div>
            </div>
            {mentionTrialDataObj?.map((itm) => {
              return (
                <div className="table-body-wrapperr">
                  <div className="table-wrap"> {itm.version} </div>
                  <div key={itm.version} className="table-wrap">
                    <input
                      type="radio"
                      name="radio-A"
                      value="A"
                      onChange={(e) => handleRadioChange(e, itm.version, 'A')}
                    />
                  </div>
                  <div className="table-wrap">
                    <input
                      type="radio"
                      name="radio-B"
                      value="B"
                      onChange={(e) => handleRadioChange(e, itm.version, 'B')}
                    />
                  </div>
                  {/* <div className="table-wrap">
                    <Radio.Group onChange={(e) => onTableAChange(e, itm.version)} defaultValue={tableAValue}>
                      <Radio value="A">A</Radio>
                    </Radio.Group>
                  </div>
                  <div className="table-wrap">
                    <Radio.Group onChange={(e) => onTableBChange(e, itm.version)} defaultValue={tableBValue}>
                      <Radio value="B">B</Radio>
                    </Radio.Group>
                  </div> */}
                  <div className="table-wrap">
                    <a target="_blank" href={itm.link} rel="noopener noreferrer" className="compare-btn-link">
                      {itm.changedDate}
                    </a>
                  </div>
                  <div className="table-wrap"> {itm.changes} </div>
                </div>
              );
            })}
          </div>
        </div>
        )}
      </Loader>
    </div>
  );
}
