import { stringify } from 'query-string';
import {
  map, catchError, mergeMap, takeUntil, debounceTime,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

export const GENERIC_ACTION = 'GENERIC_ACTION';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const ADD_PROJECT_DETAILS = 'ADD_PROJECT_DETAILS';
const ADD_PROJECT_DETAILS_CANCEL = 'ADD_PROJECT_DETAILS_CANCEL';
const ADD_PROJECT_DETAILS_RESET = 'ADD_PROJECT_DETAILS_RESET';
const ADD_PROJECT_DETAILS_SUCCESS = 'ADD_PROJECT_DETAILS_SUCCESS';
const ADD_PROJECT_DETAILS_FAILURE = 'ADD_PROJECT_DETAILS_FAILURE';

const UPDATE_PROJECT_DETAILS = 'UPDATE_PROJECT_DETAILS';
const UPDATE_PROJECT_DETAILS_SUCCESS = 'UPDATE_PROJECT_DETAILS_SUCCESS';
const UPDATE_PROJECT_DETAILS_FAILURE = 'UPDATE_PROJECT_DETAILS_FAILURE';
const UPDATE_PROJECT_DETAILS_RESET = 'UPDATE_PROJECT_DETAILS_RESET';

const GET_AUTO_SUGGEST_VAL = 'GET_AUTO_SUGGEST_VAL';
const GET_AUTO_SUGGEST_VAL_CANCEL = 'GET_AUTO_SUGGEST_VAL_CANCEL';
const GET_AUTO_SUGGEST_VAL_SUCCESS = 'GET_AUTO_SUGGEST_VAL_SUCCESS';
const GET_AUTO_SUGGEST_VAL_FAILURE = 'GET_AUTO_SUGGEST_VAL_FAILURE';
const GET_AUTO_SUGGEST_VAL_RESET = 'GET_AUTO_SUGGEST_VAL_RESET';

const GET_AUTO_SUGGEST_VAL_FOR_SUBREGION = 'GET_AUTO_SUGGEST_VAL_FOR_SUBREGION';
const GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_CANCEL = 'GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_CANCEL';
const GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_SUCCESS = 'GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_SUCCESS';
const GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_FAILURE = 'GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_FAILURE';
const GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_RESET = 'GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_RESET';

const GET_REGION_VAL = 'GET_REGION_VAL_VAL';
const GET_REGION_VAL_CANCEL = 'GET_REGION_VAL_CANCEL';
const GET_REGION_VAL_SUCCESS = 'GGET_REGION_VAL_SUCCESS';
const GET_REGION_VAL_FAILURE = 'GET_REGION_VAL_FAILURE';
const GET_REGION_VAL_RESET = 'GET_REGION_VAL_RESET';

const GET_OTHER_AUTO_SUGGEST_VAL = 'GET_OTHER_AUTO_SUGGEST_VAL';
const GET_OTHER_AUTO_SUGGEST_VAL_CANCEL = 'GET_OTHER_AUTO_SUGGEST_VAL_CANCEL';
const GET_OTHER_AUTO_SUGGEST_VAL_SUCCESS = 'GET_OTHER_AUTO_SUGGEST_VAL_SUCCESS';
const GET_OTHER_AUTO_SUGGEST_VAL_FAILURE = 'GET_OTHER_AUTO_SUGGEST_VAL_FAILURE';
const GET_OTHER_AUTO_SUGGEST_VAL_RESET = 'GET_OTHER_AUTO_SUGGEST_VAL_RESET';

// Auto Complete cancel action
export const addProjectDetailCancelAction = () => ({
  type: ADD_PROJECT_DETAILS_CANCEL,
});

// Auto Complete action
export const addProjectDetailAction = (payload) => ({
  type: ADD_PROJECT_DETAILS,
  payload,
});

// Auto Complete Success action
const addProjectDetailSuccess = (payload) => ({
  type: ADD_PROJECT_DETAILS_SUCCESS,
  payload,
  showNotification: true,
  message: 'Project added successfully',
});

// Add project reset action
export const addProjectDetailResetAction = () => ({
  type: ADD_PROJECT_DETAILS_RESET,
});

export const updateProjectDetailsAction = (payload) => ({
  type: UPDATE_PROJECT_DETAILS,
  payload,
});

export const updateProjectDetailsSuccess = (payload) => ({
  type: UPDATE_PROJECT_DETAILS_SUCCESS,
  payload,
  showNotification: true,
  message: 'Project updated successfully',
});

export const updateProjectDetailResetAction = () => ({
  type: UPDATE_PROJECT_DETAILS_RESET,
});

export const addProjectDetailEpic = (actions$) => actions$.pipe(
  ofType(ADD_PROJECT_DETAILS),
  mergeMap((action) => ajax(
    apiCall(
      `${process.env.apiUrl}v0/projects/createproject`,
      'POST',
      action.payload.body,
      true,
    ),
  ).pipe(
    map((res) => addProjectDetailSuccess(res)),
    takeUntil(actions$.pipe(ofType(ADD_PROJECT_DETAILS_CANCEL))),
    catchError((err) => of({
      type: ADD_PROJECT_DETAILS_FAILURE,
      payload: err,
    })),
  )),
);

export const updateProjectDetailEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_PROJECT_DETAILS),
  mergeMap((action) => ajax(
    apiCall(
      `${process.env.apiUrl}v0/projects/updateProject?innoplexus_id=${action.payload.innoplexus_id}`,
      'PUT',
      action.payload.body,
      true,
    ),
  ).pipe(
    map((res) => updateProjectDetailsSuccess(res)),
    catchError((err) => of({
      type: UPDATE_PROJECT_DETAILS_FAILURE,
      payload: err,
    })),
  )),
);

export function addProjectDetailReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADD_PROJECT_DETAILS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case ADD_PROJECT_DETAILS_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case ADD_PROJECT_DETAILS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case ADD_PROJECT_DETAILS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

export function updateProjectDetailReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_PROJECT_DETAILS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }

    case UPDATE_PROJECT_DETAILS_SUCCESS: {
      return {
        data: action.payload.response,
        loading: false,
        flag: true,
        error: false,
      };
    }

    case UPDATE_PROJECT_DETAILS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }

    case UPDATE_PROJECT_DETAILS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

// center
export const getautosuggestvalueAction = (payload) => ({
  type: GET_AUTO_SUGGEST_VAL,
  payload,
});

export const getautosuggestionCancelAction = () => ({
  type: GET_AUTO_SUGGEST_VAL_CANCEL,
});

export const getautosuggestionResetAction = () => ({
  type: GET_AUTO_SUGGEST_VAL_RESET,
});

const getautosuggestvalueSuccessAction = (payload) => ({
  type: GET_AUTO_SUGGEST_VAL_SUCCESS,
  payload,
})

export const getautosuggestvalueEpic = (actions$) => actions$.pipe(
  ofType(GET_AUTO_SUGGEST_VAL),
  debounceTime(500),
  mergeMap((action) => ajax(apiCall(`${process.env.apiUrl}v0/search/filterValueAutosuggest?${stringify(action.payload)}`, 'get')).pipe(
    map((res) => getautosuggestvalueSuccessAction(res.response)),
    takeUntil(actions$.pipe(ofType(GET_AUTO_SUGGEST_VAL_CANCEL))),
    catchError((err) => of({
      type: GET_AUTO_SUGGEST_VAL_FAILURE,
      payload: err,
    })),
  )),
);

export function getautosuggestvalueReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_AUTO_SUGGEST_VAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_AUTO_SUGGEST_VAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_AUTO_SUGGEST_VAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_AUTO_SUGGEST_VAL_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// other center
export const getotherautosuggestvalueAction = (payload) => ({
  type: GET_OTHER_AUTO_SUGGEST_VAL,
  payload,
});

export const getotherautosuggestionCancelAction = () => ({
  type: GET_OTHER_AUTO_SUGGEST_VAL_CANCEL,
});

export const getotherautosuggestionResetAction = () => ({
  type: GET_OTHER_AUTO_SUGGEST_VAL_RESET,
});

const getotherautosuggestvalueSuccessAction = (payload) => ({
  type: GET_OTHER_AUTO_SUGGEST_VAL_SUCCESS,
  payload,
})

export const getotherautosuggestvalueEpic = (actions$) => {
  return actions$.pipe(
    ofType(GET_OTHER_AUTO_SUGGEST_VAL),
    debounceTime(500),
    mergeMap((action) => ajax(apiCall(`${process.env.apiUrl}v0/search/filterValueAutosuggest?${stringify(action.payload)}`, 'get')).pipe(
      map((res) => getotherautosuggestvalueSuccessAction(res.response)),
      takeUntil(actions$.pipe(ofType(GET_OTHER_AUTO_SUGGEST_VAL_CANCEL))),
      catchError((err) => of({
        type: GET_OTHER_AUTO_SUGGEST_VAL_FAILURE,
        payload: err,
      })),
    )),
  );
}

export function getotherautosuggestvalueReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_OTHER_AUTO_SUGGEST_VAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_OTHER_AUTO_SUGGEST_VAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_OTHER_AUTO_SUGGEST_VAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_OTHER_AUTO_SUGGEST_VAL_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getRegionValueAction = (payload) => ({
  type: GET_REGION_VAL,
  payload,
});

export const getRegionValueCancelAction = () => ({
  type: GET_REGION_VAL_CANCEL,
});

export const getRegionValueResetAction = () => ({
  type: GET_REGION_VAL_RESET,
});

const getRegionValueSuccessAction = (payload) => ({
  type: GET_REGION_VAL_SUCCESS,
  payload,
})

export const getRegionValueEpic = (actions$) => actions$.pipe(
  ofType(GET_REGION_VAL),
  debounceTime(500),
  mergeMap((action) => ajax(apiCall(`${process.env.apiUrl}v0/search/filterValueAutosuggest?${stringify(action.payload)}`, 'get')).pipe(
    map((res) => getRegionValueSuccessAction(res.response)),
    takeUntil(actions$.pipe(ofType(GET_REGION_VAL_CANCEL))),
    catchError((err) => of({
      type: GET_REGION_VAL_FAILURE,
      payload: err,
    })),
  )),
);

export function getRegionValueReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_REGION_VAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_REGION_VAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_REGION_VAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_REGION_VAL_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getautosuggestvalueForSubregionAction = (payload) => ({
  type: GET_AUTO_SUGGEST_VAL_FOR_SUBREGION,
  payload,
});

export const getautosuggestionForSubregionCancelAction = () => ({
  type: GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_CANCEL,
});

export const getautosuggestionForSubregionResetAction = () => ({
  type: GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_RESET,
});

const getautosuggestvalueForSubregionSuccessAction = (payload) => ({
  type: GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_SUCCESS,
  payload,
})

export const getautosuggestvalueForSubregionEpic = (actions$) => actions$.pipe(
  ofType(GET_AUTO_SUGGEST_VAL_FOR_SUBREGION),
  debounceTime(500),
  mergeMap((action) => ajax(apiCall(`${process.env.apiUrl}v0/search/filterValueAutosuggest?${stringify(action.payload)}`, 'get')).pipe(
    map((res) => getautosuggestvalueForSubregionSuccessAction(res.response)),
    takeUntil(actions$.pipe(ofType(GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_CANCEL))),
    catchError((err) => of({
      type: GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_FAILURE,
      payload: err,
    })),
  )),
);

export function getautosuggestvalueForSubregionReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_AUTO_SUGGEST_VAL_FOR_SUBREGION: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_AUTO_SUGGEST_VAL_FOR_SUBREGION_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
