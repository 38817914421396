import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const ADD_RISK = 'ADD_RISK';
const ADD_RISK_SUCCESS = 'ADD_RISK_SUCCESS';
const ADD_RISK_FAILURE = 'ADD_RISK_FAILURE';

export const addRiskAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: ADD_RISK,
      url: `${process.env.apiUrl}v0/country_profile/addRisks`,
      method: 'POST',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Risk added successfully',
    hideNotification: false, // hide notification on error
  })
}

export function addRiskReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_RISK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case ADD_RISK_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    // case ADD_RISK_RESET: {
    //   return {
    //     data: {},
    //     loading: false,
    //     flag: false,
    //     error: false,
    //   }
    // }
    case ADD_RISK_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const DELETE_RISK = 'DELETE_RISK';
const DELETE_RISK_SUCCESS = 'DELETE_RISK_SUCCESS';
const DELETE_RISK_FAILURE = 'DELETE_RISK_FAILURE';

export const deleteRiskAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: DELETE_RISK,
      url: `${process.env.apiUrl}/v0/country_profile/deleteRisks?innoplexus_id=${payload.innoplexus_id}`,
      method: 'DELETE',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Risk deleted successfully',
    hideNotification: false, // hide notification on error
  })
}

export function deleteRiskReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_RISK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case DELETE_RISK_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    // case ADD_RISK_RESET: {
    //   return {
    //     data: {},
    //     loading: false,
    //     flag: false,
    //     error: false,
    //   }
    // }
    case DELETE_RISK_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const UPDATE_RISK = 'UPDATE_RISK';
const UPDATE_RISK_SUCCESS = 'UPDATE_RISK_SUCCESS';
const UPDATE_RISK_FAILURE = 'UPDATE_RISK_FAILURE';

export const updateRiskAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: UPDATE_RISK,
      url: `${process.env.apiUrl}v0/country_profile/updateRisks?innoplexus_id=${payload.innoplexus_id}`,
      method: 'PUT',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Risk updated successfully',
    hideNotification: false, // hide notification on error
  })
}

export function updateRiskReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_RISK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case UPDATE_RISK_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    // case ADD_RISK_RESET: {
    //   return {
    //     data: {},
    //     loading: false,
    //     flag: false,
    //     error: false,
    //   }
    // }
    case UPDATE_RISK_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
