import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import AddCentersManually from '../../components/AddCentersManually';

const { Option } = AutoComplete;

const AutoSuggest = ({
  apiCall, apiName, setValue, cancelAction, resetAction, selections, skey,
}) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const apiResponse = useSelector((state) => state[apiName]);
  const [searchVal, setSearchVal] = useState('')
  const [addCenter, setAddCenter] = useState(false)
  const childRef = useRef(null)

  useEffect(() => {
    if (apiResponse.flag && !apiResponse.loading) {
      if (apiResponse.data.data.length > 0) {
        setResult(apiResponse.data.data);
      } else {
        setResult([])
      }
    }
    if (apiResponse.loading) {
      setResult([])
    }
  }, [JSON.stringify(apiResponse)]);

  useEffect(() => {
    if (searchVal !== selections) {
      setSearchVal(selections);
    }
  }, [selections])

  const handleSearch = (value) => {
    if (value.trim()) {
      dispatch(cancelAction())
      apiCall(value);
      dispatch(resetAction())
      // setLoading(true)
    } else {
      setResult([])
      dispatch(resetAction())
    }
  };

  const onFocus = () => {
    apiCall(searchVal || '');
  }

  const handleCancel = () => {
    setAddCenter(false)
  };

  return (
    <>
      { skey === 'site' ? (
        <AutoComplete
          loading={apiResponse.loading}
          value={searchVal}
          onFocus={onFocus}
          onChange={(val) => { setSearchVal(val) }}
          className="input-wrapper"
          onSearch={handleSearch}
          placeholder="Type to search"
          onSelect={(value) => {
            const val = JSON.parse(value);
            setSearchVal(val.key);
            setValue(val.key, val.value)
          }}
          onBlur={() => {
            dispatch(cancelAction())
            setResult([])
            dispatch(resetAction())
          }}
          open={!addCenter}
          notFoundContent={skey === 'site' ? (
            <div>
              <div>
                Center not found
              </div>
              {/* <Button className="add-center-btn" onClick={onAddCenterClick}>Add center manually</Button> */}
            </div>
          ) : 'No data found'}
        >
          {addCenter && (
          <div
            onClick={(e) => {
              childRef.current.handleFocus()
              e.stopPropagation()
              e.preventDefault()
            }}
            role="presentation"
          >
            <Modal
              title="Add new Investigator > Add new Center"
              open={addCenter}
              onCancel={handleCancel}
            // okButtonProps={{ disabled: !!(analysisName.length < 3 || displayAnalysisNameErr) }}
              className="modal-wrapper add-investigator-modal"
              maskClosable={false}
              destroyOnClose
              footer={null}
              ref={childRef}
            >
              <div
                className="add-investigator-section add-investigator-section-lvl-up"
              >
                <AddCentersManually />
              </div>
            </Modal>
          </div>
          )}
          {result.map((res) => (
            <Option key={res.value} value={JSON.stringify(res)}>
              {res.key}
            </Option>
          ))}
        </AutoComplete>
      ) : (
        <AutoComplete
          loading={apiResponse.loading}
          value={searchVal}
          onFocus={onFocus}
          onChange={(val) => { setSearchVal(val) }}
          className="input-wrapper"
          onSearch={handleSearch}
          placeholder="Type to search"
          onSelect={(value) => {
            const val = JSON.parse(value);
            setSearchVal(val.key);
            setValue(val.key, val.value)
          }}
          onBlur={() => {
            dispatch(cancelAction())
            setResult([])
            dispatch(resetAction())
          }}
          notFoundContent="No data found"
        >
          {result.map((res) => (
            <Option key={res.value} value={JSON.stringify(res)}>
              {res.key}
            </Option>
          ))}
        </AutoComplete>
      )}
    </>
  );
};

export default AutoSuggest;
