import React from 'react'

export default function CenterPatientAccess({ data }) {
  return (
    <div className="tab-ques-wrap-content">
      {data.length ? data.map((itm) => {
        return (
          <div className="tab-ques-wrap-content-card card">
            <div className="question-wrap">
              <div className="quest-wrap">{itm.question_text}</div>
              <div className="ans-wrap"> {itm.response[0]} </div>
            </div>
          </div>
        )
      }) : <div className="no-data-found-msg">No relevant data found</div>}

    </div>
  )
}
