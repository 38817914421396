import {
  map, catchError, mergeMap, takeUntil,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

export const GENERIC_ACTION = 'GENERIC_ACTION';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const CAPACITY_GRAPH_DATA = 'CAPACITY_GRAPH_DATA';
const CAPACITY_GRAPH_DATA_CANCEL = 'CAPACITY_GRAPH_DATA_CANCEL';
const CAPACITY_GRAPH_DATA_RESET = 'CAPACITY_GRAPH_DATA_RESET';
const CAPACITY_GRAPH_DATA_SUCCESS = 'CAPACITY_GRAPH_DATA_SUCCESS';
const CAPACITY_GRAPH_DATA_FAILURE = 'CAPACITY_GRAPH_DATA_FAILURE';

export const capacityGraphDataCancelAction = () => ({
  type: CAPACITY_GRAPH_DATA_CANCEL,
});

// Auto Complete action
export const capacityGraphDataAction = (payload) => ({
  type: CAPACITY_GRAPH_DATA,
  payload,
});

// Auto Complete Success action
export const capacityGraphDataSuccessAction = (payload) => ({
  type: CAPACITY_GRAPH_DATA_SUCCESS,
  payload,
//   showNotification: true,
//   message: 'Network added successfully',
});

// Add project reset action
export const capacityGraphDataResetAction = () => ({
  type: CAPACITY_GRAPH_DATA_RESET,
});

export const getCapacityGraphDataEpic = (actions$) => actions$.pipe(
  ofType(CAPACITY_GRAPH_DATA),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}/v0/search/getCapacityGraph`, 'GET', action.payload, true),
  ).pipe(
    map((res) => capacityGraphDataSuccessAction(res)),
    takeUntil(actions$.pipe(ofType(CAPACITY_GRAPH_DATA_CANCEL))),
    catchError((err) => of({
      type: CAPACITY_GRAPH_DATA_RESET,
      payload: err,
    })),
  )),
);

export function capacityGraphDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CAPACITY_GRAPH_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case CAPACITY_GRAPH_DATA_SUCCESS: {
      return {
        data: action.payload.response,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case CAPACITY_GRAPH_DATA_RESET: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: false,
        error: false,
      };
    }
    case CAPACITY_GRAPH_DATA_FAILURE: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
