import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import '../../components/InvestigatorProfilePage/investigatorprofile.scss';
import { parse } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import TagsBar from '../../../common/components/tagsBar';
import { getTabsDeepdiveListAction } from '../DeepDiveScreen/logic';
import Loader from '../../../common/components/Loader';
import CTDeepDiveAbout from '../CTDeepDiveAbout';
import CTDeepDiveStudyDetails from '../CTDeepDivestudyDetails';
import CTDeepDiveEligibility from '../CTDeepDiveEligibility';
import CTDeepDiveInvestigatorDetails from '../CTDeepDiveInvestigatorDetails';
import CTDeepDiveResults from '../CTDeepDiveResults';
import CTDeepDiveStudyDocuments from '../CTDeepDiveStudyDocuments';
import CTDeepDiveHistoryOfChanges from '../CTDeepDiveHistoryOfChanges';
import CTDeepDiveMentionInOtherSources from '../CTDeepDiveMentionInOtherSources';
import CTDeepDiveCenterDetails from '../CTDeepDiveCenterDetails';
import CTDeepDiveRecruitment from '../CTDeepDiveRecruitment';

const navTabs = [
  {
    name: <Tooltip title="About">About</Tooltip>,
    key: 'about',
    additionalClass: '',
    title: '',
  },
  {
    name: <Tooltip title="Study Details">Study Details</Tooltip>,
    key: 'studyDetails',
    additionalClass: '',
    title: '',
  },
  {
    name: <Tooltip title="Eligibility">Eligibility</Tooltip>,
    key: 'eligibility',
    additionalClass: '',
    title: '',
  },
  // {
  //   name: <Tooltip title="Channel">Channel</Tooltip>,
  //   key: 'channel',
  //   additionalClass: '',
  //   title: '',
  // },
  {
    name: <Tooltip title="Investigators">Investigators</Tooltip>,
    key: 'investigator',
    additionalClass: '',
    title: '',
  },
  {
    name: <Tooltip title="Centers">Centers</Tooltip>,
    key: 'center',
    additionalClass: '',
    title: '',
  },
  {
    name: <Tooltip title="Results">Results</Tooltip>,
    key: 'results',
    additionalClass: '',
    title: '',
  },
  {
    name: <Tooltip title="Study Documents">Study Documents</Tooltip>,
    key: 'studyDocuments',
    additionalClass: '',
    title: '',
  },
  {
    name: <Tooltip title="History Of Changes">History Of Changes</Tooltip>,
    key: 'historyOfChanges',
    additionalClass: '',
    title: '',
  },
  {
    name: <Tooltip title="Mentions in other Sources">Mentions in other Sources</Tooltip>,
    key: 'mentionInOtherSources',
    additionalClass: '',
    title: '',
  },
  {
    name: <Tooltip title="Recruitments">Recruitments</Tooltip>,
    key: 'recruitment',
    additionalClass: '',
    title: '',
  },
];

const CTDetails = () => {
  const dispatch = useDispatch();
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }
  const trialData = useSelector((store) => store.deepDiveData)
  const [trialDataObj, setTrialDataObj] = useState([])
  const [activeTab, setActiveTab] = useState(navTabs[0].key);

  useEffect(() => {
    dispatch(getTabsDeepdiveListAction({
      clinical_id: nctId,
      tabname: 'About',
    }))
  }, [])

  useEffect(() => {
    if (trialData.flag) {
      setTrialDataObj(trialData.data)
    }
  }, [JSON.stringify(trialData)])

  const onTabChange = (tab) => {
    setActiveTab(tab.key);
  }

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'about': {
        return (
          <CTDeepDiveAbout />
        )
      }

      case 'studyDetails': {
        return (
          <CTDeepDiveStudyDetails />
        )
      }

      case 'eligibility': {
        return (
          <CTDeepDiveEligibility />
        )
      }
      // case 'channel': {
      //   return (
      //     <CTDeepDiveChannel />
      //   )
      // }
      case 'investigator': {
        return (
          <CTDeepDiveInvestigatorDetails />
        )
      }
      case 'center': {
        return (
          <CTDeepDiveCenterDetails />
        )
      }
      case 'results': {
        return (
          <CTDeepDiveResults />
        )
      }
      case 'studyDocuments': {
        return (
          <CTDeepDiveStudyDocuments />
        )
      }
      case 'historyOfChanges': {
        return (
          <CTDeepDiveHistoryOfChanges />
        )
      }
      case 'mentionInOtherSources': {
        return (
          <CTDeepDiveMentionInOtherSources />
        )
      }
      case 'recruitment': {
        return (
          <CTDeepDiveRecruitment />
        )
      }
      default: {
        return (
          <div className="no-data-found-msg">
            No relevant data found
          </div>
        );
      }
    }
  }

  return (
    <div className="whole-deep-dive-page whole-deep-dive-page-inpage">
      <Loader loading={trialData.loading} error={trialData.error}>
        {
        trialDataObj !== null && (
        <>
          <div className="middle-part">
            <div className="upper-divof-search-Tabs">
              <div className="outer_large_div">
                <div className="nav-tab-bar">
                  <div className="tags-area">
                    <TagsBar
                      activeTab={activeTab}
                      tags={navTabs}
                      onTabChange={onTabChange}
                    />
                  </div>
                  <div className="CardList">
                    {renderActiveTabContent()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        )
}
      </Loader>
    </div>
  );
}

export default CTDetails;
