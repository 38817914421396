import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

const AutoSuggest = ({
  apiCall, apiName, resetAction, setValue, cancelAction, fvalue, type, fdata, keyy,
}) => {
  const a = fdata?.filter((itm) => {
    return itm.title === type
  })

  let data = [];
  if (a && a.length && a[0]?.is_searchable) {
    data = a[0].values
  }

  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const apiResponse = useSelector((state) => state[apiName]);
  const [searchVal, setSearchVal] = useState('')
  const [localVal, setLocalVal] = useState('')
 


  useEffect(() => {
    if (apiResponse.flag && !apiResponse.loading) {
      if (apiResponse.data.data.length > 0) {
        setResult(apiResponse.data.data);
      } else {
        setResult([])
      }
    }
    if (apiResponse.loading) {
      setResult([])
    }
  }, [JSON.stringify(apiResponse)]);

  const handleSearch = (value) => {
    if (value.trim()) {
      dispatch(cancelAction())
      apiCall(value);
      setLocalVal(value)
      dispatch(resetAction())
    } else {
      setResult([])
      setLocalVal('')
      dispatch(resetAction())
    }
  };

  const onFocus = () => {
    apiCall(searchVal || '');
  }

  const handleOnChange = (value) => {
    if (value.length > 0) {
      setSearchVal(value)
      setValue(value)
    } else {
      setSearchVal([])
      setValue([])
    }
  }
  return (
    <Select
      // mode="tags"
      mode={type === 'Indications From Trials' || type === 'Speciality' ? 'multiple' : 'tags'}
      loading={apiResponse.loading}
      value={fvalue}
      onFocus={onFocus}
      onChange={handleOnChange}
      className="select-input-tag"
      onSearch={handleSearch}
      placeholder="Type to search"
      onBlur={() => {
        dispatch(cancelAction())
        setResult([])
        dispatch(resetAction())
      }}
      notFoundContent={apiResponse.loading ? ' ' : 'No data found'}
    >
      {
        localVal.length < 3 && data && data?.map((d, idx) => {
          return (
            <Option key={`${d}_${idx}`} value={d}>
              {d}
            </Option>
          )
        })
      }
      {
      localVal.length > 2 && result.map((res) => (
        <Option key={res.key} value={res.key}>
          {res.key}
        </Option>
      ))}

    
    </Select>
  );
};

export default AutoSuggest;