// import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_MENTION_OTHER_SOURCES_DATA = 'GET_MENTION_OTHER_SOURCES_DATA'
const GET_MENTION_OTHER_SOURCES_DATA_SUCCESS = 'GET_MENTION_OTHER_SOURCES_DATA_SUCCESS'
const GET_MENTION_OTHER_SOURCES_DATA_FAILURE = 'GET_MENTION_OTHER_SOURCES_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}
export const getMentionOtherSourcesDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_MENTION_OTHER_SOURCES_DATA,
    url: `${process.env.apiUrl}/v0/search/mentionsData`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getMentionOtherSourcesDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MENTION_OTHER_SOURCES_DATA: {
      return {
        data: {},
        loading: true,
        flag: true,
        error: false,
      }
    }
    case GET_MENTION_OTHER_SOURCES_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_MENTION_OTHER_SOURCES_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
