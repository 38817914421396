import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { getMentionOtherSourcesDataAction } from './logic';

export default function CTDeepDiveMentionInOtherSources() {
  const dispatch = useDispatch();
  const [mentionTrialDataObj, setMentionTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }
  const getMentionOtherSourcesDataResponse = useSelector((store) => store.getMentionOtherSourcesDataResponse)

  useEffect(() => {
    dispatch(getMentionOtherSourcesDataAction({
      clinical_id: nctId,
      from: 1,
      size: 50,
    }))
  }, [])

  useEffect(() => {
    if (getMentionOtherSourcesDataResponse.flag) {
      setMentionTabsTrialDataObj(getMentionOtherSourcesDataResponse.data.data)
    }
  }, [JSON.stringify(getMentionOtherSourcesDataResponse)])
  const getDateFormat = (ts) => {
    const dateformat = new Date(ts * 1000).toString().substring(4, 15)
    return dateformat
  }
  return (
    <div className="main-head">
      <Loader loading={getMentionOtherSourcesDataResponse.loading} error={getMentionOtherSourcesDataResponse.error}>
        {(mentionTrialDataObj && mentionTrialDataObj.length > 0) ? mentionTrialDataObj?.map((itm, idx) => {
          return (
            <div className="card mentions-in-other-sources-card">
              <div className="first-line-wrapper">
                <div className="journal-title-wrapper">{itm['Journal Title']},</div>
                <div className="source-date-wrapper">{getDateFormat(itm.Date)},</div>
                {itm['Full Text Link'] && (
                <div className="has-full-text-wrapper">
                  <a
                    target="_blank"
                    href={itm['Full Text Link']}
                    rel="noopener noreferrer"
                    className="header-link-text"
                  >
                    Has full text
                  </a>
                </div>
                )}
              </div>

              <div className="article-title-wrapper">{itm['Article Title']}</div>
              {itm.Authors && (
              <div className="author-wrapper">{itm?.Authors?.map((author) => {
                return (
                  <div>{author.kol_name},</div>
                )
              })}
              </div>
              )}
              <div className="ct-source">
                <div className="source">
                  <a
                    target="_blank"
                    href={`/search/ctdetails/mentionsources?${stringify({
                      ...parse(location.search),
                      idx: idx,
                    })}`}
                    rel="noopener noreferrer"
                    className="card-ct-link"
                  >
                    <div className="source-img" />
                  </a>
                </div>
              </div>
            </div>
          )
        }) : <div className="no-data-inside-ct">No data found</div>}
      </Loader>
    </div>
  )
}
