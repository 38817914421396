import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const REPORT_NEWLY_ADDED = 'REPORT_NEWLY_ADDED';
const REPORT_NEWLY_ADDED_SUCCESS = 'REPORT_NEWLY_ADDED_SUCCESS';
const REPORT_NEWLY_ADDED_MORE = 'REPORT_NEWLY_ADDED_MORE';
const REPORT_NEWLY_ADDED_MORE_SUCCESS = 'REPORT_NEWLY_ADDED_MORE_SUCCESS';
const REPORT_NEWLY_ADDED_FAILURE = 'REPORT_NEWLY_ADDED_FAILURE';

export const reportNewlyAddedAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: REPORT_NEWLY_ADDED,
      url: `${process.env.apiUrl}v0/search/reportNewlyAddedSitePi`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Risk added successfully',
    hideNotification: false, // hide notification on error
  })
}

export const reportNewlyAddedMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: REPORT_NEWLY_ADDED_MORE,
      url: `${process.env.apiUrl}v0/search/reportNewlyAddedSitePi`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Risk added successfully',
    hideNotification: false, // hide notification on error
  })
}

export function reportNewlyAddedReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case REPORT_NEWLY_ADDED: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case REPORT_NEWLY_ADDED_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    // case ADD_RISK_RESET: {
    //   return {
    //     data: {},
    //     loading: false,
    //     flag: false,
    //     error: false,
    //   }
    // }
    case REPORT_NEWLY_ADDED_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }

    case REPORT_NEWLY_ADDED_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state;
  }
}

const REPORT_NEWLY_ADDED_DOWNLOAD = 'REPORT_NEWLY_ADDED_DOWNLOAD';
const REPORT_NEWLY_ADDED_DOWNLOAD_SUCCESS = 'REPORT_NEWLY_ADDED_DOWNLOAD_SUCCESS';
const REPORT_NEWLY_ADDED_DOWNLOAD_FAILURE = 'REPORT_NEWLY_ADDED_DOWNLOAD_FAILURE';

export const reportNewlyAddedDownloadAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: REPORT_NEWLY_ADDED_DOWNLOAD,
      url: `${process.env.apiUrl}v0/search/reportNewlyAddedSitePi`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Risk added successfully',
    hideNotification: false, // hide notification on error
  })
}

export function reportNewlyAddedDownloadReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case REPORT_NEWLY_ADDED_DOWNLOAD: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case REPORT_NEWLY_ADDED_DOWNLOAD_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    // case ADD_RISK_RESET: {
    //   return {
    //     data: {},
    //     loading: false,
    //     flag: false,
    //     error: false,
    //   }
    // }
    case REPORT_NEWLY_ADDED_DOWNLOAD_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const REPORT_MERGED = 'REPORT_MERGED';
const REPORT_MERGED_SUCCESS = 'REPORT_MERGED_SUCCESS';
const REPORT_MERGED_MORE = 'REPORT_MERGED_MORE';
const REPORT_MERGED_MORE_SUCCESS = 'REPORT_MERGED_MORE_SUCCESS';
const REPORT_MERGED_FAILURE = 'REPORT_MERGED_FAILURE';

export const reportMergedAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: REPORT_MERGED,
      url: `${process.env.apiUrl}v0/search/reportMergedSitePi`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Risk added successfully',
    hideNotification: false, // hide notification on error
  })
}

export const reportMergedMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: REPORT_MERGED_MORE,
      url: `${process.env.apiUrl}v0/search/reportMergedSitePi`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Risk added successfully',
    hideNotification: false, // hide notification on error
  })
}

export function reportMergedReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case REPORT_MERGED: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case REPORT_MERGED_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    // case ADD_RISK_RESET: {
    //   return {
    //     data: {},
    //     loading: false,
    //     flag: false,
    //     error: false,
    //   }
    // }
    case REPORT_MERGED_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case REPORT_MERGED_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const REPORT_EDITED = 'REPORT_EDITED';
const REPORT_EDITED_SUCCESS = 'REPORT_EDITED_SUCCESS';
const REPORT_EDITED_MORE = 'REPORT_EDITED_MORE';
const REPORT_EDITED_MORE_SUCCESS = 'REPORT_EDITED_MORE_SUCCESS';
const REPORT_EDITED_FAILURE = 'REPORT_EDITED_FAILURE';

export const reportEditedAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: REPORT_EDITED,
      url: `${process.env.apiUrl}v0/search/reportEditedSitePi`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Risk added successfully',
    hideNotification: false, // hide notification on error
  })
}

export const reportEditedMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: REPORT_EDITED_MORE,
      url: `${process.env.apiUrl}v0/search/reportEditedSitePi`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Risk added successfully',
    hideNotification: false, // hide notification on error
  })
}

export function reportEditedReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case REPORT_EDITED: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case REPORT_EDITED_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case REPORT_EDITED_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case REPORT_EDITED_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
