import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_CENTER_DATA = 'GET_CENTER_DATA'
const GET_CENTER_DATA_SUCCESS = 'GET_CENTER_DATA_SUCCESS'
const GET_CENTER_DATA_FAILURE = 'GET_CENTER_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}
export const getCenterDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_CENTER_DATA,
    url: `${process.env.apiUrl}/v0/search/getCenterAnalysis`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getCenterDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_CENTER_DATA: {
      return {
        data: {},
        loading: true,
        flag: true,
        error: false,
      }
    }
    case GET_CENTER_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_CENTER_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_CENTER_DATA_DEEPDIVE = 'GET_CENTER_DATA_DEEPDIVE'
const GET_CENTER_DATA_DEEPDIVE_SUCCESS = 'GET_CENTER_DATA_DEEPDIVE_SUCCESS'
const GET_CENTER_DATA_DEEPDIVE_FAILURE = 'GET_CENTER_DATA_DEEPDIVE_FAILURE'
const GET_CENTER_DATA_DEEPDIVE_MORE = 'GET_CENTER_DATA_DEEPDIVE_MORE'
const GET_CENTER_DATA_DEEPDIVE_MORE_SUCCESS = 'GET_CENTER_DATA_DEEPDIVE_MORE_SUCCESS'

export const getCenterDataDeepDiveAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_CENTER_DATA_DEEPDIVE,
    url: `${process.env.apiUrl}/v0/search/getCenterAnalysisDetails`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getCenterDeepDiveMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_CENTER_DATA_DEEPDIVE_MORE,
    url: `${process.env.apiUrl}/v0/search/getCenterAnalysisDetails`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})
export function getCenterDataDeepDiveReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_CENTER_DATA_DEEPDIVE: {
      return {
        data: {},
        loading: true,
        flag: true,
        error: false,
      }
    }
    case GET_CENTER_DATA_DEEPDIVE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_CENTER_DATA_DEEPDIVE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }

    case GET_CENTER_DATA_DEEPDIVE_MORE_SUCCESS: {
      return {
        data: {
          data: {
            data: [
              ...state.data.data.data,
              ...action.payload.data.data,
            ],
            total: action.payload.data.total,
          },
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    default:
      return state
  }
}
