import React, { useEffect, useState } from 'react';
import {
  Button, Input,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import OutsideClick from '../../../common/components/OutsideClick';
import Loader from '../../../common/components/Loader';
import AddCenterNameModal from '../../components/AddCenterNameModal';
import { getDatasetListResetAction } from '../SearchResults/logic';

const { Search } = Input

const PiAutoSelectForCenterName = ({
  apiCall, apiName, setValuee: setValue, cancelAction, resetAction, selections, defaultValue, checkFlag,
  setPrimaryCenterCountry, country, setDisabledFlagForEthnicity, parentFlag,
}) => {
  const dispatch = useDispatch();
  const [displayDropdown, setDisplayDropdown] = useState(false)
  const [result, setResult] = useState([]);
  const apiResponse = useSelector((state) => state[apiName]);
  const [searchVal, setSearchVal] = useState(defaultValue)
  const [showValue, setShowValue] = useState(defaultValue && Object.keys(defaultValue).length === 0 ? '' : defaultValue)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (apiResponse.flag && !apiResponse.loading) {
      if (apiResponse.data.data.length > 0) {
        setResult(apiResponse.data.data);
        // setResult(apiResponse.data.data ? searchResult(apiResponse.data.data) : [])
      } else {
        setResult([])
      }
    }
    if (apiResponse.loading) {
      setResult([])
    }
  }, [JSON.stringify(apiResponse)]);

  useEffect(() => {
    if (searchVal !== selections) {
      setSearchVal(selections);
    }
  }, [selections])

  const onChangeInput = (e) => {
    setDisplayDropdown(true)
    setPrimaryCenterCountry('')
    setDisabledFlagForEthnicity(false)
    // setSearchVal(e.target.value)
    setShowValue(e.target.value)
    if (e.target.value.trim().length > 2) {
      // setFlag(false)
      dispatch(cancelAction())
      apiCall(e.target.value);
      dispatch(resetAction())
      // setSearchDisable(false)
    } else {
      // setFlag(true)
      setResult([])
      dispatch(resetAction())
      // setSearchDisable(true)
      setValue('', '')
    }
  }

  const autoSuggestionItemClicked = (value) => {
    setShowValue(value.key)
    setSearchVal(value.key, value.value);
    setPrimaryCenterCountry(value.address.country)
    if (country === undefined) {
      if (value.address.country === 'China') {
        setDisabledFlagForEthnicity(true)
      } else {
        setDisabledFlagForEthnicity(false)
      }
    }
    if (country?.value === '') {
      if (value.address.country === 'China') {
        setDisabledFlagForEthnicity(true)
      } else {
        setDisabledFlagForEthnicity(false)
      }
    }

    setValue(value.key, value.value, value?.alliance_type, value?.alliance_member_id)
    setDisplayDropdown(false)
  }

  const renderAutosuggestOptions = () => {
    if (result.length) {
      return result.map((res, index) => {
        return (
          <div
            className="search-dropdown-result-list-card"
            role="presentation"
            key={index}
            onClick={() => autoSuggestionItemClicked(res)}
          >
            <div className="ant-select-item-option autocompl-section-option">
              <div className="ant-select-item-option-content">
                <div className="label-wrap">{res.key}</div>
                <div className="address-wrap"><span className="label-title">Address :</span><span className="desc">{res?.address?.address_line1 ? res?.address?.address_line1 : '-'}</span></div>
                <div className="city-wrap"><span className="label-title">City:</span><span className="desc">{res?.address?.city ? res.address.city : '-'}</span></div>
                <div className="state-wrap"><span className="label-title">State:</span><span className="desc">{res?.address?.state ? res.address.state : '-'}</span></div>
                <div className="country-wrap"><span className="label-title">Country:</span><span className="desc">{res?.address?.country ? res.address.country : '-'}</span></div>
                <div className="zip-wrap"><span className="label-title">Zip:</span><span className="desc">{res?.address?.zip ? res.address.zip : '-'}</span></div>
                <div className="address-wrap"><span className="label-title">Alliance member:</span><span className="desc">{res?.alliance_member_name ? res.alliance_member_name : '-'}</span></div>
              </div>
            </div>
          </div>
        )
      })
    }
    if (apiResponse.error) {
      if (apiResponse.status === 404) {
        return (
          <div className="autosuggest-no-data-msg">
            <span>No matches found</span>
          </div>
        )
      }
      if (apiResponse.status === 400) {
        return (
          <div className="autosuggest-no-data-msg">
            <span>No matches found</span>
          </div>
        )
      }
    }
    if (apiResponse.flag && result.length === 0) {
      return (
        <div className="autosuggest-no-data-msg">
          <span>No matches found</span>
        </div>
      )
    }

    return null
  }

  const handleSearch = () => {
    setDisplayDropdown(false)
    setModalOpen(true)
    dispatch(getDatasetListResetAction())
  }

  return (
    <>
      <Search
        // loading={apiResponse.loading}
        // value={searchVal}
        // // onFocus={onFocus}
        // onChange={(val) => {
        //   setSearchVal(val);
        //   setValue('', '')
        // }}
        // className="input-wrapper"
        // onSearch={handleSearch}
        // placeholder="Type to search"
        // popupClassName="autocompl-section-option-sec"
        // onSelect={(value) => {
        //   console.log('checl2222')
        //   const val = JSON.parse(value);
        //   setSearchVal(val.key);
        //   setValue(val.key, val.value)
        // }}
        // // options={result}
        // onBlur={() => {
        //   dispatch(cancelAction())
        //   setResult([])
        //   dispatch(resetAction())
        //   // SetDropDownOpen(true)
        // }}
        // defaultOpen={false}
        // // open={!IsDropDownOpen}
        // // defaultValue={defaultValue}
        // notFoundContent={skey === 'site' && (
        //   <div>
        //     <div>
        //       Center not found
        //     </div>
        //     {/* <Button className="add-center-btn" onClick={onAddCenterClick}>Add center manually</Button> */}
        //   </div>
        // )}
        // enterButton={(
        //   <Button>
        //     {/* //   onClick={onSearchBtn}
        // //   disabled={!selectValue}
        //   > */}
        //     Search
        //   </Button>
        //   )}
        value={showValue}
        loading={apiResponse.loading}
        allowClear
        enterButton={(
          <Button onClick={handleSearch}>
            <span className="search-icon-btn-primary" />
          </Button>
          )}
        className="input-wrapper"
        size="large"
        maxLength="256"
        onChange={onChangeInput}
        placeholder="Type to search"
        // onPressEnter={onInputEnter}
        style={{ width: 400 }}
      />
      {/* <Input.Search size="large" placeholder="input here" /> */}
      {/* <Option>
          <div className="add-manually-btn-wrap">
            <div className="btn-wrap">
              <Button className="add-pi-btn" type="primary" onClick={onAddCenterClick}>Add center manually</Button>
            </div>
          </div>
        </Option> */}
      <div className="search-dropdown-result-content">
        {displayDropdown ? (
          <OutsideClick
            onClickOutside={() => {
              setDisplayDropdown(false)
            }}
          >
            <div className="search-dropdown-result">
              <div className="search-dropdown-result-list">
                <Loader loading={apiResponse.loading}>{renderAutosuggestOptions()}</Loader>
              </div>
            </div>
          </OutsideClick>
        ) : null}
      </div>
      {
        modalOpen ? (
          <AddCenterNameModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            setSearchVal={setSearchVal}
            setShowValue={setShowValue}
            setValue={setValue}
            checkFlag={checkFlag}
            setDisabledFlagForEthnicity={setDisabledFlagForEthnicity}
            setCountry={setPrimaryCenterCountry}
            countryField={country}
            parentFlag={parentFlag}
            flag="fromCenter"

          />

        ) : ''
      }
    </>
  );
};

export default PiAutoSelectForCenterName;
