import { parse } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { getTabsDeepdiveListAction, tabsDeepdiveResetAction } from '../DeepDiveScreen/logic';

export default function CTDeepDiveEligibility() {
  const dispatch = useDispatch();
  const [tabsTrialDataObj, setTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }
  const tabsDeepdiveResponse = useSelector((store) => store.tabsDeepdiveResponse)

  useEffect(() => {
    dispatch(getTabsDeepdiveListAction({
      clinical_id: nctId,
      tabname: 'Eligibility',
    }))
  }, [])

  useEffect(() => {
    if (tabsDeepdiveResponse.flag) {
      setTabsTrialDataObj(tabsDeepdiveResponse.data.data)
      dispatch(tabsDeepdiveResetAction())
    }
  }, [JSON.stringify(tabsDeepdiveResponse)])

  return (
    <div>
      <Loader loading={tabsDeepdiveResponse.loading} error={tabsDeepdiveResponse.error}>
        <div>
          <div className="trial-eligibilitysection trial-sec-container">
            <div className="trial-eligibility-title trial-sec-main-title">
              General
            </div>
            <div className="grid-container">
              <div className="trial-eligibility-maximumage-parent grid-item">
                <div className="trial-eligibility-maximumage-title trial-sec-title">
                  Accepts Healthy Volunteers
                </div>
                <div className="trial-eligibility-maximumage-desc trial-sec-desc">
                  {(tabsTrialDataObj?.Eligibility && tabsTrialDataObj?.Eligibility['Accepts Healthy Volunteers']) ? tabsTrialDataObj?.Eligibility['Accepts Healthy Volunteers'] : '-'}
                </div>
              </div>
              <div className="trial-eligibility-minimumage-parent grid-item">
                <div className="trial-eligibility-minimumage-title trial-sec-title">
                  Genders Eligible for Study
                </div>
                <div className="trial-eligibility-minimumage-desc trial-sec-desc">
                  {(tabsTrialDataObj?.Eligibility && tabsTrialDataObj?.Eligibility['Genders Eligible for Study']) ? tabsTrialDataObj?.Eligibility['Genders Eligible for Study'] : '-'}
                </div>
              </div>
              <div className="trial-emrollmentsection trial-sec-container grid-item">
                <div className="trial-emrollment-title trial-item-title">
                  Lower age limit for Study
                </div>
                <div className="trial-patientenrollment-actual-title trial-sec-desc">
                  {(tabsTrialDataObj?.Eligibility && tabsTrialDataObj?.Eligibility['Lower age limit for Study']) ? tabsTrialDataObj?.Eligibility['Lower age limit for Study'] : '-'}

                </div>
              </div>
              <div className="trial-emrollmentsection trial-sec-container grid-item">
                <div className="trial-emrollment-title trial-item-title">
                  Upper age limit for Study
                </div>
                <div className="trial-patientenrollment-actual-title trial-sec-desc">
                  {(tabsTrialDataObj?.Eligibility && tabsTrialDataObj?.Eligibility['Upper age limit for Study']) ? tabsTrialDataObj?.Eligibility['Upper age limit for Study'] : '-'}

                </div>
              </div>
              <div className="trial-patientenrollment-target-title grid-item">
                <div className="trial-patientenrollment-target-title trial-sec-title">
                  Study Population
                </div>
                <div className="trial-patientenrollment-target-title trial-sec-desc">
                  {tabsTrialDataObj?.Eligibility?.Study_Population ? tabsTrialDataObj?.Eligibility?.Study_Population : '-'}
                </div>
              </div>
            </div>
          </div>

          <div className="trial-eligibilitysection trial-sec-container">
            <div className="trial-eligibility-title trial-sec-main-title">
              ELIGIBILITY CRITERIA
            </div>
            <div className="trial-eligibility-maximumage-parent">
              <div className="trial-eligibility-maximumage-desc trial-sec-desc">
                {tabsTrialDataObj?.Criteria && tabsTrialDataObj?.Criteria['Raw Eligibility Criteria'] ? tabsTrialDataObj?.Criteria['Raw Eligibility Criteria'] : '-'}
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </div>
  )
}
