import { parse } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { getTabsDeepdiveListAction, tabsDeepdiveResetAction } from '../DeepDiveScreen/logic';

export default function CTDeepDiveStudyDocuments() {
  const dispatch = useDispatch();
  const [tabsTrialDataObj, setTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }
  const tabsDeepdiveResponse = useSelector((store) => store.tabsDeepdiveResponse)

  useEffect(() => {
    dispatch(getTabsDeepdiveListAction({
      clinical_id: nctId,
      tabname: 'Study Documents',
    }))
  }, [])

  useEffect(() => {
    if (tabsDeepdiveResponse.flag) {
      setTabsTrialDataObj(tabsDeepdiveResponse.data.data)
      dispatch(tabsDeepdiveResetAction())
    }
  }, [JSON.stringify(tabsDeepdiveResponse)])

  return (
    <div className="main-head">
      <Loader loading={tabsDeepdiveResponse.loading} error={tabsDeepdiveResponse.error}>
        {(tabsTrialDataObj && tabsTrialDataObj.length > 0) ? tabsTrialDataObj?.map((itm) => {
          return (
            <div className="link-study-doc">
              <div className="link-study-doc-content">
                <a
                  target="_blank"
                  href={itm.url}
                  rel="noopener noreferrer"
                  className="card-ct-link"
                >
                  {itm.type},
                </a>
              </div>
              <div className="link-study-doc-content-date">{itm.date}</div>
            </div>
          );
        }) : <div className="no-data-inside-ct">No data found</div>}
      </Loader>
    </div>
  )
}
