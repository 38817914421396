import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = AutoComplete;

const PiAutoSelect = ({
  apiCall, apiName, setValuee: setValue, cancelAction, resetAction, selections, skey, defaultValue,
}) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const apiResponse = useSelector((state) => state[apiName]);
  const [searchVal, setSearchVal] = useState(defaultValue)

  useEffect(() => {
    if (apiResponse.flag && !apiResponse.loading) {
      if (apiResponse.data.data.length > 0) {
        setResult(apiResponse.data.data);
      } else {
        setResult([])
      }
    }
    if (apiResponse.loading) {
      setResult([])
    }
  }, [JSON.stringify(apiResponse)]);

  useEffect(() => {
    if (searchVal !== selections) {
      setSearchVal(selections);
    }
  }, [selections])

  const handleSearch = (value) => {
    if (value !== '') {
      apiCall(value);
      dispatch(resetAction())
    } else {
      setResult([])
      dispatch(resetAction())
    }
  };

  return (
    <>
      <AutoComplete
        loading={apiResponse.loading}
        value={searchVal}
        // onFocus={onFocus}
        onChange={(val) => {
          setSearchVal(val);
          setValue('', '', '')
        }}
        className="input-wrapper"
        onSearch={handleSearch}
        placeholder="Type to search"
        popupClassName="autocompl-section-option-sec"
        onSelect={(value) => {
          const val = JSON.parse(value);
          setSearchVal(val.key);
          setValue(val.key, val.value, val.alliance_type)
        }}
        onBlur={() => {
          dispatch(cancelAction())
          setResult([])
          dispatch(resetAction())
          // SetDropDownOpen(true)
        }}
        defaultOpen={false}
        // open={!IsDropDownOpen}
        // defaultValue={defaultValue}
        notFoundContent={skey === 'site' && (
          <div>
            <div>
              Center not found
            </div>
            {/* <Button className="add-center-btn" onClick={onAddCenterClick}>Add center manually</Button> */}
          </div>
        )}
      >
        {/* <Option>
          <div className="add-manually-btn-wrap">
            <div className="btn-wrap">
              <Button className="add-pi-btn" type="primary" onClick={onAddCenterClick}>Add center manually</Button>
            </div>
          </div>
        </Option> */}
        {result.map((res) => (
          <>
            <Option className="autocompl-section-option" key={res.value} value={JSON.stringify(res)}>
              <div className="label-wrap">{res.key}</div>
              <div className="address-wrap"><span className="label-title">Address :</span><span className="desc">{res?.address?.address_line1 ? res?.address?.address_line1 : '-'}</span></div>
              <div className="city-wrap"><span className="label-title">City:</span><span className="desc">{res?.address?.city ? res.address.city : '-'}</span></div>
              <div className="state-wrap"><span className="label-title">State:</span><span className="desc">{res?.address?.state ? res.address.state : '-'}</span></div>
              <div className="country-wrap"><span className="label-title">Country:</span><span className="desc">{res?.address?.country ? res.address.country : '-'}</span></div>
              <div className="zip-wrap"><span className="label-title">Zip:</span><span className="desc">{res?.address?.zip ? res.address.zip : '-'}</span></div>
              <div className="address-wrap"><span className="label-title">Alliance member:</span><span className="desc">{res?.alliance_member_name ? res.alliance_member_name : '-'}</span></div>
            </Option>
          </>
        ))}

      </AutoComplete>
    </>
  );
};

export default PiAutoSelect;
