import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Form, Select, DatePicker,
}
from 'antd';

const CenterAndDemographics = ({ data }) => {
  const getPiFilterDataResponse = useSelector((store) => store.getPiFilterDataResponse);

  useEffect(() => {
    if (data?.country === undefined) {
      if (data.primaryCenterCountry === 'China') {
        if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
          data.setCenter((oldState) => {
            const temp = {};
            temp.ethnicity = '';
            return { ...oldState, ...temp };
          });
        }
      }
    }
    if (data?.country?.value === '') {
      if (data.primaryCenterCountry === 'China') {
        data.setDisabledFlagForEthnicity(true);
        data.setCenter((oldState) => {
          let temp = {};
          temp.ethnicity = oldState.ethnicity;
          if (typeof temp.ethnicity === 'object') {
            temp.ethnicity.value = '';
            temp.ethnicity.flag = true;
          } else {
            temp = {};
            const value = '';
            temp.ethnicity = { value, flag: true };
          }
          return { ...oldState, ...temp };
        });
      }
    }
  }, [data.primaryCenterCountry]);

  useEffect(() => {
    if (data?.country?.value && data?.country?.value === undefined) {
      if (data.primaryCenterCountry === 'China') {
        data.setDisabledFlagForEthnicity(true);
      } else {
        data.setDisabledFlagForEthnicity(false);
      }
    }
  }, [data?.primaryCenterCountry]);

  useEffect(() => {
    if (data?.country && data?.country === 'China') {
      if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
        data.setCenter((oldState) => {
          const temp = {}
          temp.ethnicity = ''
          return { ...oldState, ...temp }
        })
      }
    }
    if (data?.country?.value && data?.country?.value === 'China') {
      data.setCenter((oldState) => {
        let temp = {};
        temp.ethnicity = oldState.ethnicity;
        if (typeof temp.ethnicity === 'object') {
          temp.ethnicity.value = '';
          temp.ethnicity.flag = true;
        } else {
          temp = {}
          const value = ''
          temp.ethnicity = { value, flag: true };
        }
        return { ...oldState, ...temp };
      });
    }
  }, [data?.country])

  const getValue = (keyName) => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      return data.center[keyName]
    }
    return data.center[keyName].value
  }

  const { Option } = Select;

  const handleChange = (value, keyName) => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setCenter((oldState) => {
        const temp = {}
        temp[keyName] = value
        return { ...oldState, ...temp }
      })
    } else {
      data.setCenter((oldState) => {
        let temp = {};
        temp[keyName] = oldState[keyName];
        if (typeof temp[keyName] === 'object') {
          temp[keyName].value = value;
          temp[keyName].flag = true;
        } else {
          temp = {}
          temp[keyName] = { value, flag: true };
        }
        return { ...oldState, ...temp };
      });
    }
  };

  const onChange = (date, dateString) => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setCenter((oldState) => {
        const temp = {}
        temp.year_of_birth = dateString
        return { ...oldState, ...temp }
      })
    } else {
      data.setCenter((oldState) => {
        let temp = {};
        temp.year_of_birth = oldState.year_of_birth;
        if (typeof temp.year_of_birth === 'object') {
          temp.year_of_birth.value = dateString;
          temp.year_of_birth.flag = true;
        } else {
          temp = {}
          temp.year_of_birth = { value: dateString, flag: true };
        }
        return { ...oldState, ...temp }
      })
    }
  };

  return (
    <div className="investigator-page-wrapper">
      <div className={data.flag === 'admin' ? 'page-wrapper-in-section disable-pi' : 'page-wrapper-in-section'}>
        {/* <div className="main-head-wrap">Center</div> */}
        <Form
          layout="vertical"
          form={data.centerForm}
          className={data.flag === 'admin' ? 'disable-pi-form' : null}
        >
          <div className="head-wrapper">
            <div className="inner-card">
              {/* <div className="name-card">
                <Form.Item
                  label="Primary center name"
                  name="centername"
                  rules={[{ required: true, message: 'This is a required field' }]}
                  className="name-wrapper primary-center-name"
                >
                  <PiAutoSelectForCenterName
                    apiCall={onCenterChangeInput}
                    apiName="autosuggestdata"
                    selections={getValue('primaryCenter')}
                    setValuee={(val, key) => { setCenter({ val, key }) }}
                    cancelAction={getautosuggestionCancelAction}
                    resetAction={getautosuggestionResetAction}
                    skey="site"
                    IsDropDownOpen={data.IsDropDownOpen}
                    SetDropDownOpen={data.SetDropDownOpen}
                    defaultValue={getValue('primaryCenter')}
                    checkFlag={data.flag}
                  />
                </Form.Item>
              </div>

              <div className="title-card">
                <Form.Item
                  label="Other research centers"
                  className="title-wrapper other-research-center"
                  name="othercenter"
                >
                  <OtherResearchAutoSuggest
                    mode="multiple"
                    selections={otherCenter}
                    setValue={setOtherCenter}
                    Value={getValue('other_centers')}
                    placeholder="Select centers"
                    style={{
                      width: '100%',
                    }}
                    defaultValue={getValue('other_centers')}
                    fetchOptions={onOtherCenterChangeInput}
                    checkFlag={data.flag}
                  />
                </Form.Item>
              </div> */}

              {/* <div className="title-card">
                <Form.Item
                  label="Degree"
                  name="degree"
                  className="title-wrapper"
                  rules={[{ type: 'string', max: 120, message: 'Maximum 120 characters' }]}
                >
                  <Select
                    placeholder="Select Degree"
                    style={{
                      width: 120,
                    }}
                    onChange={(e) => handleChange(e, 'degree')}
                    value={getValue('degree')}
                    defaultValue={getValue('degree')}
                    showSearch
                  >
                    {getPiFilterDataResponse && getPiFilterDataResponse?.data?.data?.degree.map((itm) => {
                      return <Option value={itm.value}>{itm.key}</Option>
                    })}
                  </Select>
                </Form.Item>
              </div> */}

            </div>
          </div>
        </Form>
        <div className="main-head-wrap">Demographics</div>
        <Form layout="vertical" form={data.centerForm} className={data.flag === 'admin' ? 'disable-pi-form' : null}>
          <div className="head-wrapper">
            <div className="inner-card">
              <div className="name-card">
                <Form.Item
                  label="Year of birth"
                  className="name-wrapper"
                >
                  <DatePicker onChange={onChange} picker="year" value={getValue('year_of_birth') ? moment(getValue('year_of_birth'), 'YYYY') : ''} disabledDate={(d) => !d || d.isBefore('1920') || d.isAfter(`${new Date().getFullYear()}`)} />
                </Form.Item>
              </div>

              <div className="gender-card">
                <Form.Item
                  label="Gender"
                  className="gender-wrapper"
                >
                  <Select
                    placeholder="Select gender"
                    style={{
                      width: 120,
                    }}
                    onChange={(e) => handleChange(e, 'gender')}
                    value={getValue('gender')}
                  >
                    {getPiFilterDataResponse && getPiFilterDataResponse?.data?.data?.gender.map((itm) => {
                      return <Option value={itm.value}>{itm.key}</Option>
                    })}
                  </Select>
                </Form.Item>
              </div>

              <div className="ethnic-card">
                <Form.Item
                  label="Ethnic origin"
                  className="title-wrapper"
                >
                  <Select
                    placeholder="Select origin"
                    style={{
                      width: 120,
                    }}
                    onChange={(e) => handleChange(e, 'ethnicity')}
                    value={getValue('ethnicity')}
                    disabled={data.country === 'China' || data.country?.value === 'China' || data.disabledFlagForEthnicity}
                  >
                    {getPiFilterDataResponse && getPiFilterDataResponse?.data?.data?.ethnicity.map((itm) => {
                      return <Option value={itm.value}>{itm.key}</Option>
                    })}
                  </Select>
                </Form.Item>
              </div>

              <div className="language-card">
                <Form.Item
                  label="Languages spoken"
                  className="language-wrapper"
                >
                  <Select
                    mode="multiple"
                    placeholder="Select languages"
                    style={{
                      width: 120,
                    }}
                    onChange={(e) => handleChange(e, 'languages_spoken')}
                    value={getValue('languages_spoken')}
                  >
                    {getPiFilterDataResponse && getPiFilterDataResponse?.data?.data?.languages_spoken.map((itm) => {
                      return <Option value={itm.value}>{itm.key}</Option>
                    })}
                  </Select>
                </Form.Item>
              </div>

            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CenterAndDemographics;
