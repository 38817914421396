import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { Table } from 'antd';
import { getTabsDeepdiveListAction, tabsDeepdiveResetAction } from '../DeepDiveScreen/logic';
import Loader from '../../../common/components/Loader';

export default function CTDeepDiveRecruitment() {
  const columns = [
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: 'Subjects Enrolled',
      dataIndex: 'subject_count',
    },
  ];
  const [recruitmentData, setRecruitmentData] = useState([])
  const dispatch = useDispatch()
  const tabsDeepdiveResponse = useSelector((store) => store.tabsDeepdiveResponse)
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }

  useEffect(() => {
    dispatch(getTabsDeepdiveListAction({
      clinical_id: nctId,
      tabname: 'About',
    }))
  }, [])

  useEffect(() => {
    if (tabsDeepdiveResponse.flag) {
      setRecruitmentData(tabsDeepdiveResponse.data.data.enrolled_subjects_data)
      dispatch(tabsDeepdiveResetAction())
    }
  }, [JSON.stringify(tabsDeepdiveResponse)])
  return (
    <Loader loading={tabsDeepdiveResponse.loading}>
      <div>
        <Table
          columns={columns}
          dataSource={recruitmentData}
          pagination={false}
          scroll={{
            y: 240,
          }}
        />
      </div>
    </Loader>
  )
}
