import React, { useEffect, useState } from 'react'
import { Collapse, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { parse, stringify } from 'query-string';
import { getTabsDeepdiveListAction, tabsDeepdiveResetAction } from '../DeepDiveScreen/logic';
import Loader from '../../../common/components/Loader';

export default function CTDeepDiveResults() {
  const dispatch = useDispatch();
  const [tabsTrialDataObj, setTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }
  const tabsDeepdiveResponse = useSelector((store) => store.tabsDeepdiveResponse)
  const { Panel } = Collapse;

  useEffect(() => {
    dispatch(getTabsDeepdiveListAction({
      clinical_id: nctId,
      tabname: 'Results',
    }))
  }, [])

  useEffect(() => {
    if (tabsDeepdiveResponse.flag) {
      setTabsTrialDataObj(tabsDeepdiveResponse.data.data)
      dispatch(tabsDeepdiveResetAction())
    }
  }, [JSON.stringify(tabsDeepdiveResponse)])


  return (
    <div className="main-container">
      <div id="ontosight-details-tab-content" className="tabs-content scrollbar">
        <Loader loading={tabsDeepdiveResponse.loading} error={tabsDeepdiveResponse.error}>

          <div className="tab-content">
            <div className="result-tab" style={{ minHeight: '62vh' }}>
              <Space direction="vertical">
                <Collapse defaultActiveKey={['1']} >
                  <Panel header="Primary Outcome Measures" key="1">
                    <div data-test="accordion-body-container" className="ct-results-accordion-body">
                      {(tabsTrialDataObj && tabsTrialDataObj.length > 0) ? tabsTrialDataObj?.results?.Primary?.map((itm, idx) => {
                        return (
                          <>
                            <div className="result-item card has-bg">
                              <div className="card-details">
                                <div className="card-meta-title">{itm.type}</div>
                                <div className="card-title">{itm.title}</div>
                                <div className="card-desc">{itm.time_frame}</div>
                                <div className="ct-source">
                                  <div className="source">
                                    <a
                                      target="_blank"
                                      href={`/search/ctdetails/resultdeepdive?${stringify({
                                        ...parse(location.search),
                                        idx: idx,
                                      })}`}
                                      rel="noopener noreferrer"
                                      className="card-ct-link"
                                    >
                                      <div className="source-img" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }) : <div className="no-data-inside-ct">No data found</div>}
                    </div>
                  </Panel>
                </Collapse>
              </Space>

              <Space direction="vertical">
                <Collapse defaultActiveKey={['1']} >
                  <Panel header="Secondary Outcome Measures" key="1">
                    <div data-test="accordion-body-container" className="ct-results-accordion-body">
                      {(tabsTrialDataObj && tabsTrialDataObj.length > 0) ? tabsTrialDataObj?.results?.Secondary?.map((itm, idx) => {
                        return (
                          <>
                            <div className="result-item card has-bg">
                              <div className="card-details">
                                <div className="card-meta-title">{itm.type}</div>
                                <div className="card-title">{itm.title}</div>
                                <div className="card-desc">{itm.time_frame}</div>
                                <div className="ct-source">
                                  <div className="source">
                                    <a
                                      target="_blank"
                                      href={`/search/ctdetails/resultdeepdive?${stringify({
                                        ...parse(location.search),
                                        idx: idx,
                                      })}`}
                                      rel="noopener noreferrer"
                                      className="card-ct-link"
                                    >
                                      <div className="source-img" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }) : <div className="no-data-inside-ct">No data found</div>}
                    </div>
                  </Panel>
                </Collapse>
              </Space>
            </div>
          </div>
        </Loader>
      </div>
    </div>
  )
}
