import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { getTabsDeepdiveListAction, tabsDeepdiveResetAction } from '../DeepDiveScreen/logic';

export default function CTDeepDiveStudyDetails() {
  const dispatch = useDispatch();
  const [tabsTrialDataObj, setTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  let nctId
  if (Array.isArray(cid)) {
    nctId = cid.filter((option) => option.startsWith('NCT'));
  } else {
    nctId = cid
  }
  const tabsDeepdiveResponse = useSelector((store) => store.tabsDeepdiveResponse)

  useEffect(() => {
    dispatch(getTabsDeepdiveListAction({
      clinical_id: nctId,
      tabname: 'Study Details',
    }))
  }, [])

  useEffect(() => {
    if (tabsDeepdiveResponse.flag) {
      setTabsTrialDataObj(tabsDeepdiveResponse.data.data)
      dispatch(tabsDeepdiveResetAction())
    }
  }, [JSON.stringify(tabsDeepdiveResponse)])

  return (
    <div>
      <Loader loading={tabsDeepdiveResponse.loading} error={tabsDeepdiveResponse.error}>

        <div className="trial-investigatorsection">
          <div className="trial-investigator-title trial-sec-container">
            <div className="trial-investigator-title trial-sec-main-title">TYPE</div>
            {tabsTrialDataObj['Study Type'] ? <div className="trial-investigator-name-parent-title trial-sec-desc">{tabsTrialDataObj['Study Type']}</div> : '-'}
          </div>
          <div className="trial-investigator-title trial-sec-container">
            <div className="trial-investigator-title trial-sec-main-title">DESIGN</div>
            <div className="trial-investigator-name-parent-title trial-sec-desc">
              <div>
                Allocation: {tabsTrialDataObj['Study Design']?.Allocation ? tabsTrialDataObj['Study Design']?.Allocation : '-'}
              </div>
              <div>
                Intervention model: {(tabsTrialDataObj['Study Design'] && tabsTrialDataObj['Study Design']['Intervention model']) ? tabsTrialDataObj['Study Design']['Intervention model'] : '-'}
              </div>
              <div>
                Primary purpose: {(tabsTrialDataObj['Study Design'] && tabsTrialDataObj['Study Design']['Primary purpose']) ? tabsTrialDataObj['Study Design']['Primary purpose'] : '-'}
              </div>
              <div>
                Masking: {tabsTrialDataObj['Study Design']?.Masking ? tabsTrialDataObj['Study Design']?.Masking : '-'}
              </div>
            </div>
          </div>
          <div className="trial-investigator-title trial-sec-container">
            <div className="trial-investigator-title trial-sec-main-title">
              ARMS AND ACTIVE INTERVENTION
            </div>
            <div className="trial-table-header">
              <div className="trial-investigator-name-parent-title trial-sec-desc">Arm</div>
              <div className="trial-investigator-name-parent-title trial-sec-desc">
                Intervention/treatment
              </div>
            </div>
            <div className="trial-table-body-con">
              <div className="trial-table-body">
                { tabsTrialDataObj?.studyDetailTable?.map((item) => {
                  return (
                    <>
                      <div className="trial-investigator-name-parent-title trial-sec-desc">
                        {item.Arm.type ? <div className="trial-sec-desc-head">{item.Arm.type}: {item.Arm.label}</div> : <div className="trial-sec-desc-head">{item.Arm.label}</div>}
                        <div className="trial-sec-desc-data">{item.Arm.description}</div>
                      </div>
                      <div className="trial-investigator-name-parent-title trial-sec-desc">
                        {item['Intervention/treatment']?.map((d) => {
                          return (
                            <div>
                              <div className="trial-sec-desc-head">{d.type}: {d?.name}</div>
                              <div className="trial-sec-desc-data">{d.detail}</div>
                              <div className="trial-sec-desc-data">Other name: {d['Other Name']}</div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="trial-investigator-title trial-sec-container">
            <div className="trial-investigator-title trial-sec-main-title">
              PRIMARY OUTCOME MEASURE
            </div>
            <div className="trial-sec-container-head">
              <div className="trial-investigator-name-parent-title trial-sec-desc">Time Frame</div>
              <div className="trial-investigator-name-parent-title trial-sec-desc">Measure</div>
              <div className="trial-investigator-name-parent-title trial-sec-desc">Description</div>
            </div>
            { tabsTrialDataObj['Primary Outcome Measures'] && tabsTrialDataObj['Primary Outcome Measures'].length > 0 ? tabsTrialDataObj['Primary Outcome Measures']?.map((p) => {
              return (
                <div className="trial-sec-container-body">
                  <div className="trial-investigator-name-parent-title trial-sec-desc">
                    {p.Duration}
                  </div>
                  <div className="trial-investigator-name-parent-title trial-sec-desc">
                    {p.Metric}
                  </div>
                  <div className="trial-investigator-name-parent-title trial-sec-desc">
                    {p.Detail}
                  </div>
                </div>
              )
            }) : <div className="no-data-inside-ct">No data found</div>}
          </div>

          <div className="trial-investigator-title trial-sec-container">
            <div className="trial-investigator-title trial-sec-main-title">
              SECONDARY OUTCOME MEASURE
            </div>
            <div className="trial-sec-container-head">
              <div className="trial-investigator-name-parent-title trial-sec-desc">Time Frame</div>
              <div className="trial-investigator-name-parent-title trial-sec-desc">Measure</div>
              <div className="trial-investigator-name-parent-title trial-sec-desc">Description</div>
            </div>
            { tabsTrialDataObj['Secondary Outcome Measures'] && tabsTrialDataObj['Secondary Outcome Measures'].length > 0 ? tabsTrialDataObj['Secondary Outcome Measures']?.map((s) => {
              return (
                <div className="trial-sec-container-body">
                  <div className="trial-investigator-name-parent-title trial-sec-desc">
                    {s.Duration}
                  </div>
                  <div className="trial-investigator-name-parent-title trial-sec-desc">
                    {s.Metric}
                  </div>
                  <div className="trial-investigator-name-parent-title trial-sec-desc">
                    {s.Detail}
                  </div>
                </div>
              )
            }) : <div className="no-data-inside-ct">No data found</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}
