import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select, Radio, Button, InputNumber, Rate, Checkbox,
} from 'antd';

import { getAnalysisFilterAction } from '../RunAnalysis/logic';
import Loader from '../../../common/components/Loader';

const { Option } = Select;
const apiBasedFilters = ['indications', 'countries', 'primary_center_types', 'compliance', 'networks', 'alliance_type'];
const recordTypeOptions = ['Principal investigator', 'Primary organization', 'Parent institute'];
const reliabilityOptions = ['Class 1', 'Class 2', 'Class 3'];

const AnalysisFilters = (props) => {
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    alliance: 'All',
    is_added: 'All',
    is_added_by_sponsor: 'All',
    is_commented: 'All',
    quality_rating: undefined,
    tier: undefined,
  });
  const [selectedRecordType, setSelectedRecordType] = useState('Principal investigator');
  const [disabledQualityStars, setDisableQualityStars] = useState(false);
  const runAnalysisFilters = useSelector((state) => state.runAnalysisFilters);

  useEffect(() => {
    apiBasedFilters.forEach((type) => {
      dispatch(getAnalysisFilterAction({
        analysis_id: props.analysisId,
        type,
      }, {
        filter_type: type,
        key: type,
      }))
    })
  }, []);

  useEffect(() => {
    setSelectedFilters(props.appliedFilters);
  }, [JSON.stringify(props.appliedFilters)])

  const handleRecordTypeChange = (val) => {
    setSelectedRecordType(val);
  }

  const onRadioFilterChange = (e, key) => {
    const tempFilters = { ...selectedFilters };
    tempFilters[key] = e.target.value;
    setSelectedFilters(tempFilters);
  }

  const onClearClick = () => {
    setSelectedFilters({
      alliance: 'All',
      is_added: 'All',
      is_added_by_sponsor: 'All',
      is_commented: 'All',
      quality_rating: undefined,
      tier: undefined,
    });
    props.onReset();
  }

  const handleChange = (value, key) => {
    const tempFilters = { ...selectedFilters };
    tempFilters[key] = value;
    setSelectedFilters(tempFilters);
  }

  const onQualityRatingChange = (newValue) => {
    if (newValue.target && newValue.target.checked) {
      setDisableQualityStars(true);
    } else {
      setDisableQualityStars(false);
    }
    const tempFilters = { ...selectedFilters };
    tempFilters.quality_rating = newValue.target && newValue.target.checked ? 0 : newValue;
    setSelectedFilters(tempFilters);
  }

  const onTierChange = (newValue) => {
    const tempFilters = { ...selectedFilters };
    tempFilters.tier = newValue;
    setSelectedFilters(tempFilters);
  }

  const handleCountChange = (e, type) => {
    if (e !== null) {
      if (type === 'lower') {
        const temp = [e, selectedFilters.enrolled_count ? selectedFilters.enrolled_count[1] : props.enrolled_count_default[1]]
        const tempFilters = { ...selectedFilters };
        tempFilters.enrolled_count = temp;
        setSelectedFilters(tempFilters);
      } else if (type === 'upper') {
        const temp = [selectedFilters.enrolled_count ? selectedFilters.enrolled_count[0] : props.enrolled_count_default[0], e]
        const tempFilters = { ...selectedFilters };
        tempFilters.enrolled_count = temp;
        setSelectedFilters(tempFilters);
      } else if (type === 'studieslower') {
        const temp = [e, selectedFilters.industry_studies ? selectedFilters.industry_studies[1] : props.industry_studies_default[1]]
        const tempFilters = { ...selectedFilters };
        tempFilters.industry_studies = temp;
        setSelectedFilters(tempFilters);
      } else if (type === 'studiesupper') {
        const temp = [selectedFilters.industry_studies ? selectedFilters.industry_studies[0] : props.industry_studies_default[0], e]
        const tempFilters = { ...selectedFilters };
        tempFilters.industry_studies = temp;
        setSelectedFilters(tempFilters);
      }
    }
  }

  return (
    <div className="filter-runanalysis-popover-inside">
      <Loader loading={Object.keys(runAnalysisFilters.data).length !== 6 && runAnalysisFilters.loading} error={runAnalysisFilters.error}>
        {runAnalysisFilters.flag && Object.keys(runAnalysisFilters.data).length === 6
          ? (
            <div className="analysis-filter-popover">
              <div className="analysis-filter-section">
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Indications</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search indications"
                      onChange={(val) => handleChange(val, 'indications')}
                      maxTagCount={1}
                      value={selectedFilters.indications || []}
                    >
                      {runAnalysisFilters?.data?.indications?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Country</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search countries"
                      onChange={(val) => handleChange(val, 'countries')}
                      maxTagCount={1}
                      value={selectedFilters.countries || []}
                    >
                      {runAnalysisFilters?.data?.countries?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Compliance issue</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search compliance issues"
                      onChange={(val) => handleChange(val, 'compliance')}
                      maxTagCount={1}
                      value={selectedFilters.compliance || []}
                    >
                      {runAnalysisFilters?.data?.compliance?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Network</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search networks"
                      onChange={(val) => handleChange(val, 'networks')}
                      maxTagCount={1}
                      value={selectedFilters.networks || []}
                    >
                      {runAnalysisFilters?.data?.networks?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {props.pageName === 'savedAnalysisResults'
                  ? (
                    <div className="analysis-filter-section-filter">
                      <div className="analysis-filter-section-filter-select">
                        <div className="filter-value-title">Reliability</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search reliability"
                          onChange={(val) => handleChange(val, 'reliability_class')}
                          maxTagCount={1}
                          value={selectedFilters.reliability_class || []}
                        >
                          {reliabilityOptions.map((filterItm, index) => (
                            <Option key={index} value={filterItm}>
                              {filterItm}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Alliance type</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search alliance types"
                      onChange={(val) => handleChange(val, 'alliance_type')}
                      maxTagCount={1}
                      value={selectedFilters.alliance_type || []}
                    >
                      {runAnalysisFilters?.data?.alliance_type?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-radio">
                    <div className="filter-value-title">Alliance</div>
                    <div className="radio-btn-multiple">
                      <Radio.Group value={selectedFilters.alliance} onChange={(e) => onRadioFilterChange(e, 'alliance')}>
                        <Radio value="All">All</Radio>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Organization type</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search Organization Type"
                      onChange={(val) => handleChange(val, 'primary_center_types')}
                      maxTagCount={1}
                      value={selectedFilters.primary_center_types || []}
                    >
                      {runAnalysisFilters?.data?.primary_center_types?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {props.pageName === 'savedAnalysisResults'
                  ? (
                    <div className="analysis-filter-section-filter">
                      <div className="analysis-filter-section-filter-radio">
                        <div className="filter-value-title">Manually added</div>
                        <div className="radio-btn-multiple">
                          <Radio.Group value={selectedFilters.is_added} onChange={(e) => onRadioFilterChange(e, 'is_added')}>
                            <Radio value="All">All</Radio>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  )
                  : null}
                {props.pageName === 'savedAnalysisResults'
                  ? (
                    <div className="analysis-filter-section-filter">
                      <div className="analysis-filter-section-filter-radio">
                        <div className="filter-value-title">Added from sponsor&apos;s list</div>
                        <div className="radio-btn-multiple">
                          <Radio.Group value={selectedFilters.is_added_by_sponsor} onChange={(e) => onRadioFilterChange(e, 'is_added_by_sponsor')}>
                            <Radio value="All">All</Radio>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  )
                  : null}
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-radio">
                    <div className="filter-value-title">With comments</div>
                    <div className="radio-btn-multiple">
                      <Radio.Group value={selectedFilters.is_commented} onChange={(e) => onRadioFilterChange(e, 'is_commented')}>
                        <Radio value="All">All</Radio>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                {props.pageName === 'savedAnalysisResults'
                  ? (
                    <div className="analysis-filter-section-combine  analysis-filter-section-combine-rating">
                      <div className="analysis-filter-section-filter">
                        <div className="analysis-filter-section-filter-select">
                          <div className="rang-filter-wrap">
                            <div className="rang-filter-wrap-title">
                              <div className="filter-value-title">Quality rating of</div>
                              <div className="record-type-wrap">
                                <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                                  {recordTypeOptions.map((recordType, index) => (
                                    <Option value={recordType} key={index} disabled={index !== 0}>
                                      {recordType}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="range-filter-wrap range-filter-wrap-combine">
                              <Rate
                                disabled={disabledQualityStars}
                                count={3}
                                defaultValue={selectedFilters.quality_rating ? selectedFilters.quality_rating : 0}
                                value={selectedFilters.quality_rating ? selectedFilters.quality_rating : 0}
                                onChange={onQualityRatingChange}
                              />
                              <span className="or-sepration">OR</span>
                              <div className="filter-input-chkbx">
                                <Checkbox onChange={onQualityRatingChange}>Unknown</Checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="analysis-filter-section-filter">
                        <div className="analysis-filter-section-filter-select">
                          <div className="rang-filter-wrap">
                            <div className="rang-filter-wrap-title">
                              <div className="filter-value-title">Tier</div>
                              <div className="record-type-wrap">
                                <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                                  {recordTypeOptions.map((recordType, index) => (
                                    <Option value={recordType} key={index} disabled={index !== 0}>
                                      {recordType}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="range-filter-wrap range-filter-wrap-combine">
                              <Select mode="multiple" value={selectedFilters.tier ? selectedFilters.tier : []} onChange={onTierChange}>
                                <Option value={0}>0</Option>
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  : null}
                <div className="analysis-filter-section-combine">
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title"># Industrial studies by</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType} key={index} disabled={index !== 0}>
                                  {recordType}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <InputNumber
                            min={props.industry_studies_default ? props.industry_studies_default[0] : 0}
                            max={props.industry_studies_default[1] - 1}
                            value={selectedFilters.industry_studies ? selectedFilters.industry_studies[0] : props.industry_studies_default[0]}
                            onChange={(e) => handleCountChange(e, 'studieslower')}
                          />
                          <span className="input-separator" />
                          <InputNumber
                            min={props.industry_studies_default[0] + 1}
                            max={props.industry_studies_default[1] || 1000}
                            value={selectedFilters.industry_studies ? selectedFilters.industry_studies[1] : props.industry_studies_default[1]}
                            onChange={(e) => handleCountChange(e, 'studiesupper')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title"># Patients enrolled by</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType} key={index} disabled={index !== 0}>
                                  {recordType}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <InputNumber
                            min={props.enrolled_count_default ? props.enrolled_count_default[0] : 0}
                            max={props.enrolled_count_default[1] - 1}
                            // disabled
                            onChange={(e) => handleCountChange(e, 'lower')}
                            value={selectedFilters.enrolled_count ? selectedFilters.enrolled_count[0] : props.enrolled_count_default[0]}
                          />
                          <span className="input-separator" />
                          <InputNumber
                            min={props.enrolled_count_default[0] + 1}
                            max={props.enrolled_count_default[1] || 100000}
                            // disabled
                            onChange={(e) => handleCountChange(e, 'upper')}
                            value={selectedFilters.enrolled_count ? selectedFilters.enrolled_count[1] : props.enrolled_count_default[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-submit-btns">
                <Button className="clear-btn" onClick={() => onClearClick()}>Clear</Button>
                <Button className="apply-btn" type="primary" onClick={() => props.onApplyFilters(selectedFilters)}>Apply</Button>
              </div>
            </div>
          )
          : null}
      </Loader>
    </div>
  )
}

export default AnalysisFilters;
