import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

const GET_ROLES_FOR_INVITE = 'GET_ROLES_FOR_INVITE'
const GET_ROLES_FOR_INVITE_SUCCESS = 'GET_ROLES_FOR_INVITE_SUCCESS'
const GET_ROLES_FOR_INVITE_RESET = 'GET_ROLES_FOR_INVITE_RESET'
const GET_ROLES_FOR_INVITE_FAILURE = 'GET_ROLES_FOR_INVITE_FAILURE'

export const getRoleForInviteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_ROLES_FOR_INVITE,
      url: `${process.env.apiUrl}/v0/auth/getRoles`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getRoleForInviteResetAction = () => ({
  type: GET_ROLES_FOR_INVITE_RESET,
})

export function getRoleForInviteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_ROLES_FOR_INVITE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_ROLES_FOR_INVITE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_ROLES_FOR_INVITE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_ROLES_FOR_INVITE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// POST api call

const INVITE_USER = 'INVITE_USER'
const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS'
const INVITE_USER_RESET = 'INVITE_USER_RESET'
const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE'

export const inviteUserAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: INVITE_USER,
      url: `${process.env.apiUrl}v0/auth/inviteUser?${stringify(payload.params)}`,
      method: 'POST',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Parent Institution sent for approval',
    hideNotification: false,
    // customErrMessage: 'Warning : Parent Institution already exists with this name',
  })
}

export function inviteUserReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case INVITE_USER: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case INVITE_USER_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case INVITE_USER_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case INVITE_USER_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
